@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

$icon-size: 15px;
$left-padding: 30px;

.secondaryNavigation {
  width: 225px;
  background: #4d6977;
  height: 100vh;
  position: sticky;
  overflow-y: auto;
  transition: width 0.1s ease;
  top: 0;
  margin-left: 75px;
  .navigationContainer {
    padding: 94px 0 46px 30px;

    section.inactive {
      .subheading {
        display: none;
      }
    }

    .heading {
      font-size: 0.875rem;
      font-weight: 600;
      color: white;
      display: flex;
      padding: 8px 0 8px 8px;
      gap: 8px;
      align-items: center;
      width: calc(100% - 38px);
      border-radius: 3px;
      margin-top: 1px;
      margin-bottom: 1px;

      &:hover {
        background-color: $crystal-midnight-blue;
      }

      svg {
        height: 15px;
        width: 15px;
        stroke: white;
        stroke-width: 2px;
      }

      a {
        font-size: 0.875rem;
        font-weight: 600;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    .subHeadingSection {
      display: flex;
      flex-direction: column;
      gap: 1px;
      padding-left: 24px;
      width: calc(100% - 54px);
      .subheading {
        padding: 5px 0 4px 8px;
        border-radius: 3px;
        a {
          font-size: 1rem;
          font-weight: 400;
          line-height: 140%;
          color: $light-gray-text-two;
          &:hover {
            color: $crystal-midnight-blue;
            text-decoration: none;
          }
        }
        &.active {
          background-color: $crystal-midnight-blue;
          a {
            color: white;
          }
        }
      }
    }

    .utilities {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      padding: 8px 0px 8px 8px;
      border-top: 1px solid #cecece;
      row-gap: 8px;
      width: calc(100% - 38px);

      .icon {
        width: 15px;
        height: 15px;
      }

      a {
        display: flex;
        gap: 8px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 140%;
        color: $light-gray-text-two;
        padding: 4px 0px;

        &:hover {
          color: $crystal-midnight-blue;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: $main-content-width) {
    display: none;
  }

  .icon {
    @include svg-bold();

    &.personality {
      stroke-width: 1;
    }
  }
}
