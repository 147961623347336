@import '@crystal-eyes/constants.scss';

.navHeader.expanded {
  padding-left: 8px;
}

.poweredBy {
  color: $crystal-midnight-blue;
  font-style: italic;
  font-size: 0.63rem;
}

.whiteLabelArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
  padding: 8px;
  background-color: $white;
  border-radius: 4px;
  align-self: center;

  img {
    object-fit: contain;
  }
}

.emptyLogos {
  height: 74px;
}
